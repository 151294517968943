/* Reset some basic elements */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

/* Apply a background color similar to the one in the image */
.mainContainer {
  background-color: #2D2D46; /* Adjust the color as needed */
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Style the h1 element to be more prominent */
h1 {
  font-size: 4em;
  color: #C9A602; /* Adjust the color to match the golden tone in the image */
  margin-bottom: 0.5em;
}

/* Style the data container */
.dataContainer {
  background-color: #3E3E5E; /* Adjust the color as needed */
  padding: 2em;
  border-radius: 20px;
  width: 80%;
  max-width: 600px;
  margin: 1em;
}

/* General button styling */
.button {
  background-color: #6C63FF; /* Adjust the color as needed */
  border: none;
  padding: 1em 2em;
  border-radius: 10px;
  margin-top: 1em;
  cursor: pointer;
  color: white;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

/* Button hover effect */
.button:hover {
  background-color: #7C72FF; /* Adjust the color to a lighter shade */
}

/* Input field styling */
.input {
  padding: 1em;
  border-radius: 10px;
  border: none;
  width: calc(100% - 2em); /* Subtract padding from total width */
  margin-top: 1em;
}

/* Style the individual info boxes */
.dataContainer div {
  background-color: #4D4D6F; /* Adjust the color to a slightly different shade for contrast */
  margin-top: 1em;
  padding: 1em;
  border-radius: 10px;
}

/* When an account is connected, change the button style */
.accountConnected .button {
  background-color: #4CAF50; /* Green color to indicate success */
}

/* Add responsiveness to make it look good on mobile */
@media (max-width: 768px) {
  .dataContainer {
    width: 95%;
  }

  h1 {
    font-size: 2.5em;
  }
}